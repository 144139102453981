import '@brskl/core/dist/lib.css';
import '@brskl/ui-lib/dist/lib.css';
import '@/core/styles/main.scss';
import 'regenerator-runtime';
import '@/core/styles.scss';

import { init, modules, api } from '@brskl/core';
import { createApp } from 'vue';
import { checkVersion } from '@/core/scripts/version-checker';
import { i18n, translateLocales } from '@/core/i18n';
import mainEvent from '@/core/events/main';
import router from '@/core/router';
import store from '@/core/store';
import App from '@/core/App.vue';
import * as channel from '@/core/channels/channel';
import { checkTokens } from '@/core/scripts/token-checker';

import * as tradePoints from '@/apps/trade-points';
import * as terminals from '@/apps/terminals';
import * as entities from '@/apps/entities';
import * as reports from '@/apps/reports';
import * as orders from '@/apps/orders';
import * as items from '@/apps/items';
import * as main from '@/apps/main';
import * as registration from '@/apps/registration';
import * as catalogsCategories from '@/apps/catalogs-categories';
import * as storageReports from '@/apps/storage-reports';
import * as profileStore from '@/apps/profile';
import * as documents from '@/apps/documents';
import * as events from '@/apps/events';
import * as mobileFooterStore from '@/core/components/TableFooterMobile/';
import * as serviceCenter from '@/apps/service_center';
import timezone from '@/core/helpers/timezone';
import timeformat from '@/core/helpers/timeformat';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

import VueYandexMetrika from '@/core/utils/metrika';

const devHosts = ['new.business.briskly.dev', 'localhost'];
const isDev = !!IS_DEV || devHosts.includes(location.host);

const { auth, profile } = modules;
const app = createApp(App);
window.store = store;

(async () => {
  await checkVersion();

  api.endpoints.setCurrent(api.endpoints.business.company);

  init({
    WEBSOCKET_URL: process.env.WEBSOCKET_URL,
    // WEBSOCKET_URL: 'ws://localhost:9000/',
    AUTH_CHECK_SALT: process.env.AUTH_CHECK_SALT,
    API_URL: process.env.API_URL,
    PROJECT_NAME,
    router,
    store,
    i18n,
    app,
  });

  const queryTokens = checkTokens();
  if (queryTokens) {
    store.commit('core$main/setIsSupport');
  }

  channel.init(api.endpoints.current);

  // await channel.get(); //delete

  mainEvent.init(store);

  await auth.connect({ selectedCountry: 'ru', store, router, locales: translateLocales });
  await profile.connect({ store, router });
  await catalogsCategories.connect();
  await storageReports.connect();
  await profileStore.connect();
  await registration.connect();
  await tradePoints.connect();
  await terminals.connect();
  await documents.connect();
  await entities.connect();
  await reports.connect();
  await orders.connect();
  await events.connect();
  await items.connect();
  await main.connect();
  await mobileFooterStore.connect();
  await serviceCenter.connect();
  await app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(timezone);
  app.use(timeformat);

  // PrimeVue
  app.use(PrimeVue)
  app.use(ConfirmationService)

  app.use(VueYandexMetrika, {
    id: 71742211,
    router,
    env: isDev ? 'development' : 'production',
    debug: false,
    options: {
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    },
  });

  app.mount('#app');
})();
