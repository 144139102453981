// --- INJECTED ---
import { getI18n } from '@/core/i18n';
const i18n = getI18n();
// --- /INJECTED ---

const breadCrumb = [
    {
        label: i18n.t('Оплаты'),
        route: { name: 'orders$orders' },
    },
    {
        label: i18n.t('Оплата № :id'),
        route: { name: 'orders$info' },
    },
    {
        label: i18n.t('Оформление возврата'),
    },
];

export default [
    {
        path: '/orders',
        name: 'orders$orders',
        component: () => import('@/apps/orders/views/Orders'),
        meta: {
            breadCrumb: breadCrumb.slice(0, 1),
            title: i18n.t('Оплаты'),
        },
    },
    {
        path: '/orders/:id',
        name: 'orders$order',
        component: () => import('@/apps/orders/views/Order/Order'),
        redirect: { name: 'orders$info' },
        meta: {
            breadCrumb: breadCrumb.slice(0, 2),
            title: i18n.t('Оплата'),
        },
        children: [
            {
                path: 'info',
                name: 'orders$info',
                component: () => import('@/apps/orders/views/Order/tabs/MainInfo/MainInfo'),
                meta: {
                    title: i18n.t('Основная информация'),
                },
            },
            {
                path: 'video',
                name: 'orders$video',
                // redirect: { name: 'orders$orders' } /* removed until frontend is done */,
                component: () => import('@/apps/orders/views/Order/tabs/Video/Video'),
                meta: {
                    title: i18n.t('Видео'),
                },
            },
            {
                path: 'refunds',
                name: 'orders$refunds',
                component: () => import('@/apps/orders/views/Order/tabs/Refunds/Refunds'),
                meta: {
                    title: i18n.t('Возвраты'),
                },
            },
            {
              path: 'events',
              name: 'orders$events',
              component: () => import('@/apps/orders/views/Order/tabs/Events/Events'),
              meta: {
                title: i18n.t('События'),
              },
            },
            {
                path: 'create-refund',
                name: 'orders$createRefund',
                component: () => import('@/apps/orders/views/Order/tabs/CreateRefund/CreateRefund'),
                meta: {
                    breadCrumb,
                    title: i18n.t('Создание возврата'),
                },
            },
        ],
    },
];
